import { useEffect } from "react";

export function useOutsideClick(ref, onClickOut, deps = []) {
  useEffect(() => {
    const onClick = (event) => {
      !ref?.contains(event.target) && onClickOut?.();
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [deps, onClickOut, ref]);
}
