export const rule = [
    {
        title: "Do we need a waiver to enter?",
        description: "Yes! All parents or guardians must sign a waiver before entry into our facility. When arriving, you must have a legal form of identification. (drivers license or government issued I.D.)",
    },
    {
        title: "What socks do we wear?",
        description: "Keiki Kingdom requires all guests to wear Keiki Kingdom grip socks at all time. Socks are $3 a pair and are for guests to keep after purchase. They are reusable for repeat visits.",
    },
    {
        title: "Do I have to stay with my child(ren) at all times?",
        description: "Children must be supervised by at least a parent/guardian at all times. Adult supervision for all ages is required.",
    },
    {
        title: "Can we bring our own toys to the playground?",
        description: "Do NOT bring your own toys here. They can be left with the parents or at the lockers.",
    },
    {
        title: "Can we bring in food and beverages?",
        description: "No outside food and drinks allowed. Drinking and Eating are not permitted in the play area. No gum allowed in the facility.",
    },
    {
        title: "What should we wear?",
        description: "Active wear is prefered, please avoid any loose items/clothing with neck drawstrings and jewelry. We provide lockers with locks for storage.",
    },
    {
        title: "What if we lose something?",
        description: "Please understand, we are NOT responsible for any lost, damaged, or forgotten items. If you are missing something please email cs@keikikingdom.com. Items left longer than 14 days and are not claimed will be donated or thrown away.",
    },
    {
        title: "What are your rules of conduct while playing?",
        description: "Any rude, rough play, or inappropriate behavior will not be tolerated by any child or adult.",
    },
    {
        title: "What if my child isn't feeling well?",
        description: "If you of your child are sick, please stay home and come back to Keiki Kingdom when you and your child are feeling better.",
    },
    {
        title: "Are there age restrictions?",
        description: "Yes, Keiki Kingdom is for children 13 and under. Please follow the rules and age limits in designated areas.",
    },
    {
        title: "Is there any risk for my child playing here?",
        description: "All playgrounds have an inherent risk of injury. You assume the risk for yourself and the children you bring. We are not responsible for loss, theft, or damage of personal property.",
    },
    {
        title: "Can my child play when they have a medical condition?",
        description: "Yes, but any medical condition that may hinder your child's enjoyment of our facility should be reported to our staff.",
    },

]
    /*
{
title: "What should we wear?",
description: "Everyone who is playing in our facility must wear our Keiki Kingdom socks. Shoes, sneakers, and sandals are not permitted. Items like jewelry, watches and loose clothing should be removed from every adult and child prior to entering in the play area. Remember we don't want anything getting caught, damaged or broken.",
},
{
title: "Can we bring in food and beverages?",
description: "You cannot carry food or beverages into the facility unless it is for a booked birthday party or event. Only baby formula & water is permitted. Baby formula, water, and any purchased snacks from our cafe must be consumed in the cafe area only. Pre-made meals, food, baby pouches, etc are not allowed to be brought in. This is not a peanut free facility. You can go in and out of our facility to your car, etc. during your purchased block of time.",
},
{
title: "Can we bring our own toys to the playground?",
description: "Do NOT bring your own toys here. They can be left with the parents or the lockers.",
},
{
title: "Do we need a waiver to enter?",
description: "Yes! All parents or guardians must sign a waiver before entry into our facility. When arriving, you must have a legal form of identification. (drivers license or government issued I.D.)",
},
{
title: "Can we bring toys with us to different areas?",
description: "Please keep all toys in the designated play area. This includes the balls inside the ball pits.",
},
{
title: "Do you have security policy in place to protect the children?",
description: "Parents/guardians are responsible for their children at all times. NO EXCEPTIONS. Parents & children are given stickers that identify their children. Please do not remove them. They are to be worn at all times while in our facility. One of our staff members will remove these stickers before you leave and match each parent and child to ensure all children leave with the correct guardian. Adults without children are not allowed to enter the facility or only under the direct supervision of a staff member. Adults without children are not allowed to take pictures of our facility. When taking pictures inside our facility with your child make sure if another child is in your picture frame; first ask the parent if it is okay with them to take the picture.",
},
{
title: "Do I have to stay with my child(ren) at all times?",
description: "Parental supervision is required at ALL TIMES. This is not a daycare. No drop offs. There must be one legal guardian responsible for monitoring their children at all times while they play in our facility. Parents sitting on the side should routinely check on their children.",
},
{
title: "What are your rules of conduct while playing?",
description: "Any rude, rough play, or inappropriate behavior will not be tolerated by any child or adult. In the event a child or adult violates this policy, they will be asked to leave with NO refund. Using inappropriate language will also result in your removal. Patrons are required to follow the requests of staff members at all times.",
},
{
title: "What if we lose something?",
description: "Please understand, are are NOT responsible for any lost, damaged, or forgotten items. If you are missing something please email example@gmail.com regarding the item you are missing so we can check our lost & found for you. Items left longer than 10 days and are not claimed will be donated or thrown away.",
},
{
title: "How long is our ticket good for?",
description: "The admission ticket is for 2 hours from the time of purchase and check-in. However, if it rains or is a holiday, your admission ticket could vary based on current offerings. We are open from Mon-Thurs: 10am-6pm and Fri-Sun: 10am-7pm. If you arrive an hour before close, the normal ticket rate price applies. There are no discounts or prorated tickets.",
},
{
title: "What if my child isn't feeling well?",
description: "If your child is not feeling well, coughing, running a fever, or visibly sick they should not be brought into our facility. Any child visibly deemed to not be feeling well will not be allowed entry. Any child that vomits or has a bloody nose will be asked to leave immediately; no refunds granted. Children with casts or recent medical conditions are not advised to play at our facility; however the guardian legally makes that decision on behalf of the child and accepts all responsibility. We take the safety and well-being of the children playing here very seriously and want to protect all our guests… please use common courtesy.",
},
*/