import React, { useRef, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import Footer from "../footer/Footer";
import TransitionPage from "../TransitionPage";
import downloadPDF from "../../assets/download/Job-App-Form.pdf";
import "./ContactStyles.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
// import GoogleMap from './GoogleMap.jsx'

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [reason, setReason] = useState("Employment");
  const isReasonEmployment = reason === "Employment";

  // const location = {
  //     lat: 21.299760,
  //     lng: -157.837200,
  // }

  const form = useRef();

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file && isReasonEmployment) {
      alert("Please attach a resume");
      return;
    }

    emailjs.sendForm("service_3rodn4d", "KKHonolulu", form.current, "user_hWvLrmHqw4gG9NX2HMToy").then(
      (result) => {
        console.log(result.text);
        setSuccess(true);
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        alert("Something went wrong, please try again later");
      }
    );
  };

  return (
    <>
      <TransitionPage>
        <div className="contact">
          <div className="container">
            <h1 className="title">Contact</h1>
            <h3>Reach out with any questions or comments!</h3>
            <div className="main-content">
              <div
                className="content-form content"
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{ type: "spring", duration: 2 }}>
                {success ? (
                  <h2 className="success">Thank you for your message!</h2>
                ) : (
                  <form encType="multipart/form-data" method="post" ref={form} onSubmit={handleSubmit}>
                    <p>
                      Fields marked with an <span>*</span> are required
                    </p>
                    <input type="hidden" name="location" value="Honolulu" />
                    <label>
                      {" "}
                      What is this regarding? <span>*</span>
                      <select name="reason" value={reason} onChange={handleReasonChange}>
                        <option value="Employment">- Employment -</option>
                        <option value="General Inquire">- General Question or Comment -</option>
                        <option value="Party Booking">- Book a Private Party -</option>
                        <option value="Lost and Found">- Lost and Found -</option>
                      </select>
                    </label>
                    {isReasonEmployment && (
                      <div className="pdf-download-container">
                        <a href={downloadPDF} download>
                          <FaFileDownload /> Download Resume Here<span> *</span>
                        </a>
                      </div>
                    )}
                    <label>
                      Name <span>*</span>
                      <input type="text" name="name" className="input" required placeholder="Name" />
                    </label>
                    <label>
                      Email <span>*</span>
                      <input type="email" name="email" className="input" required placeholder="Email" />
                    </label>
                    {isReasonEmployment && (
                      <>
                        <label htmlFor="pdf_upload">
                          Attach Resume <span>*</span>
                        </label>
                        <input
                          className="file-attach"
                          type="file"
                          name="pdf_upload"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </>
                    )}
                    <label>
                      Message <span>*</span>
                      <textarea
                        type="text"
                        name="message"
                        className="input-message"
                        required
                        placeholder="Your Message Here..."
                      />
                    </label>
                    <div className="recaptcha">
                      <ReCAPTCHA sitekey="6LfBZ3YqAAAAAA2n97RrDjD64rqRgwAyQNt2TxhP" />
                    </div>

                    <button>Submit</button>
                  </form>
                )}
              </div>
              <div
                className="content-info content"
                initial={{ x: "100vw" }}
                animate={{ x: 0 }}
                transition={{ type: "spring", duration: 2 }}>
                <div className="call">
                  <h2>Contact Us</h2>
                  <h3 className="phone-number">(808) 515-3454</h3>
                </div>

                <div className="find">
                  <h2>Find Us</h2>
                  <h3>
                    1460 S Beretania St.
                    <br />
                    Honolulu, HI 96814
                  </h3>
                </div>

                <div className="hours">
                  <h2>Our Hours</h2>
                  <h3>
                    Sun - Thur <br />
                    9am - 8pm <br /> <br />
                    Fri - Sat <br />
                    9am - 9pm
                  </h3>
                </div>
              </div>
            </div>
            {/* <div className="googlemap">
                            <GoogleMap location={location} />

                        </div> */}
          </div>
        </div>
        <Footer />
      </TransitionPage>
    </>
  );
};

export default Contact;
