import React, { useRef } from "react";
import "./ModalStyles.css";
import { FaWindowClose } from "react-icons/fa";
import { useOutsideClick } from "../../hooks/useOutsideClick";

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  useOutsideClick(modalRef.current, () => {
    if (isOpen) {
      onClose();
    }
  });

  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content" onClick={(event) => event.stopPropagation()}>
        <FaWindowClose className="close-button" onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
