import ballpit from "../assets/activties/ballpit.webp";
import sandbox from "../assets/activties/sandpit.webp";
import carousel from "../assets/activties/carousel.webp";
import trampoline from "../assets/activties/trampoline.webp";
import slides from "../assets/activties/slides.webp";
import dropslide from "../assets/activties/dropslide.webp";
import roleplay from "../assets/activties/roleplay.webp";
import funride from "../assets/activties/funride.webp";
import foamcannon from "../assets/activties/foamcannon.webp";

export const activity = [
  {
    title: "Ball Pit",
    image: ballpit,
  },
  {
    title: "SandBox",
    image: sandbox,
  },
  {
    title: "Carousel",
    image: carousel,
  },
  {
    title: "Trampoline",
    image: trampoline,
  },
  {
    title: "Slides",
    image: slides,
  },
  {
    title: "Drop Slide",
    image: dropslide,
  },
  {
    title: "Roleplay",
    image: roleplay,
  },
  {
    title: "Go Karts",
    image: funride,
  },
  {
    title: "Foam Cannon",
    image: foamcannon,
  },
];
