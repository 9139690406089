import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/character/logo.webp";
import "./FooterStyles.css";
import { FaYelp, FaFacebookSquare, FaInstagram } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="placeholder"></div>

      <footer>
        <div className="container">
          <img
            src={logo}
            alt="keikikingdom footer logo"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />

          <ul>
            <li
              className={
                window.location.pathname === "/"
                  ? "link-hover link__hover-color active"
                  : "link-hover link__hover-color"
              }>
              <Link to="/">Home</Link>
            </li>
            <li
              className={
                window.location.pathname === "/calendar"
                  ? "link-hover link__hover-color active"
                  : "link-hover link__hover-color"
              }>
              <Link to="/calendar">Calendar</Link>
            </li>
            <li
              className={
                window.location.pathname === "/rules"
                  ? "link-hover link__hover-color active"
                  : "link-hover link__hover-color"
              }>
              <Link to="/rules">Rules</Link>
            </li>
            <li
              className={
                window.location.pathname === "/price"
                  ? "link-hover link__hover-color active"
                  : "link-hover link__hover-color"
              }>
              <Link to="/price">Price</Link>
            </li>
            <li
              className={
                window.location.pathname === "/contact"
                  ? "link-hover link__hover-color active"
                  : "link-hover link__hover-color"
              }>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          {/* <ul>
                        <li className='link-hover link__hover-color'><Link to='#'>Legal</Link></li>
                        <li className='link-hover link__hover-color'><Link to='#'>Privacy Policy</Link></li>

                    </ul> */}
          <ul>
            <li className="link-hover link__hover-color">
              <a
                href="https://www.facebook.com/KeikiKingdom"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="facebook link">
                <FaFacebookSquare />
              </a>
            </li>
            <li className="link-hover link__hover-color">
              <a
                href="https://www.instagram.com/keikikingdom/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram link">
                <FaInstagram />
              </a>
            </li>
            <li className="link-hover link__hover-color">
              <a
                href="https://www.yelp.com/biz/keiki-kingdom-urban-honolulu"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="yelp link">
                <FaYelp />
              </a>
            </li>
          </ul>
          <div className="copyright">
            <p>Copyright &copy; {currentYear} Keiki Kingdom</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
